// Our colors
$om-semantic-blue: #2185d0;
$om-semantic-orange: #e27736;
$brand-gray-dark: #8b8b8b;
$brand-bg: #f6f6f6;
$brand-text: rgba(0, 0, 0, 0.87);
$brand-gray: #d1d1d1;
$brand-gray-light: #e8e8e8;
$error: #9f3a38;

// Bootstrap variables
$primary: $om-semantic-blue;
$secondary: $om-semantic-orange;
$text-muted: rgba(0, 0, 0, 0.6);
$body-bg: $brand-bg;
$body-color: $brand-text;

$theme-colors: (
  'gray-dark': $brand-gray-dark,
  'gray': $brand-gray,
  'gray-light': $brand-gray-light,
  'body-color': $body-color,
);

$font-family-sans-serif: 'Inter', sans-serif;
$font-family-base: $font-family-sans-serif;
$font-size-base: 0.875rem;
$font-size-lg: 1rem;
$headings-font-weight: 600;

$card-cap-bg: #fff;

$grid-columns: 16;
$grid-gutter-width: 24px;

$list-group-item-padding-y: 1.25rem;
$list-group-item-padding-x: 1.5rem;

// Bootstrap's default variables
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
