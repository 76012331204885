@import './variables';

.ng-select.custom.ng-select-multiple {
  .ng-select-container .ng-value-container .ng-value {
    background-color: $brand-gray-light;
    border-radius: $border-radius-sm;
    border: 1px solid darken($brand-gray-light, 10%);

    .ng-value-icon.right {
      border-left: 1px solid $brand-gray-light;

      &:hover {
        background-color: darken($brand-gray-light, 10%);
      }

      .icon-anchor {
        width: 0;
      }
    }
  }

  .ng-option {
    &.ng-option-selected,
    &.ng-option-marked {
      background-color: $brand-gray-light;
    }
  }
}
