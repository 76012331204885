@import 'fonts';

// Import our theme
@import 'variables';

// Generate bootstrap global styles
@import 'node_modules/bootstrap/scss/bootstrap';
@import '~@ng-select/ng-select/themes/default.theme.css';
@import 'ng-select-custom';

// Additional styles
html,
body {
  height: 100%;
}

// `hidden` attribute preferred over Bootstrap's `.hidden`
// since it also hides it from assistive devices
[hidden] {
  display: none !important;
}

.inline-icon-with-text {
  display: inline-flex;
  align-items: center;

  svg {
    display: block;
  }
}
